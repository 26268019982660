.noteFilter {
  color: var(--foreground-secondary);
  max-width: 30rem;
  margin: 1rem auto;
  display: flex;
  flex-wrap: wrap;
}

.noteFilter ul {
  justify-content: center;
}

.noteFilter li {
  margin: 0.75rem;
}

.noteFilter button {
  margin: 0.5rem;
  font-size: 0.875rem;
  text-decoration: none;
  border: 1px solid var(--accent-3);
  background: var(--accent-1);
  color: var(--foreground-secondary);
  height: 2.25rem;
  padding: 0 1rem;
  border-radius: 2rem;
  display: inline-flex;
  align-items: center;
  gap: 0.25rem;
}

.noteFilter button.active,
.noteFilter button:hover {
  color: var(--foreground);
  background: var(--accent-2);
}

.noteFilter svg {
  width: 1rem;
  height: 1rem;
}

.tagText {
  line-height: 1;
}
