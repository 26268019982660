header {
  padding: 2rem 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.headerLeft {
  display: flex;
  align-items: center;
}

.headerLeft h1 {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  font-size: 1rem;
  font-weight: 400;
}

.headerRight {
  display: flex;
  align-items: center;
}

.authInfo {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.greeting {
  color: var(--foreground);
}

.spinner,
.check {
  color: var(--foreground-secondary);
  width: 1.25rem;
  height: 1.25rem;
}

.spinner {
  animation: spin 1s infinite;
}

@keyframes spin {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

.signOutButton,
.signInButton {
  font-size: 1rem;
  border-radius: 2.25rem;
  height: 2.25rem;
  color: var(--foreground);
  border: 1px solid var(--accent-3);
  background: var(--accent-1);
  padding: 0 1rem;
}

.signInButton {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.gIcon {
  height: 1.25rem;
  width: 1.25rem;
}

.signOutButton:hover,
.signInButton:hover {
  background: var(--accent-2);
}

@media (max-width: 768px) {
  header {
    padding: 1rem;
  }

  .signInButton {
    width: 36px;
    padding: 0;
    align-items: center;
    justify-content: center;
  }

  .signInButton span {
    display: none;
  }

  .headerLeft .description {
    display: none;
  }
}
