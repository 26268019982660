:root {
  --cyan: 191 97% 77%;
  --green: 135 94% 65%;
  --orange: 31 100% 71%;
  --pink: 326 100% 74%;
  --purple: 265 89% 78%;
  --red: 0 100% 67%;
  --yellow: 65 92% 76%;
  --grey: 0 0% 20%;
  --background: hsl(0 0% 0%);
  --foreground: hsl(0 0% 100%);
  --foreground-secondary: hsl(0 0% 50%);
  --accent-1: hsl(0 0% 4%);
  --accent-2: hsl(0 0% 8%);
  --accent-3: hsl(0 0% 12%);
  --accent-4: hsl(0 0% 16%);
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  font-family: "Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: transparent;
  background: var(--background);
  color: var(--foreground);
  user-select: none;
  max-width: 70rem;
  margin: auto;
}

main {
  padding: 0 0.5rem;
}

input {
  font-family: inherit;
}

button {
  cursor: pointer;
  font-family: inherit;
  transition: all 250ms ease;
  font-size: 1rem;
}

p {
  font-size: 1.125rem;
  line-height: 1.5rem;
}

::placeholder {
  color: var(--foreground-secondary);
}

@media (max-width: 768px) {
  p {
    font-size: 1rem;
  }
}
