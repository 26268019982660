:global(.note) {
  position: absolute;
  backdrop-filter: blur(5px);
  border-radius: 1rem;
  width: 22rem;
  transition: all 250ms ease;
  display: flex;
  flex-direction: column;
}

:global(.note.dragging) {
  transition: none;
  z-index: 1000 !important;
}

.editNote {
  display: flex;
  flex-direction: column;
}

.deleteButton {
  border: none;
  background: hsl(var(--red));
  color: var(--background);
  font-size: 1rem;
  height: 2.25rem;
  padding: 0 1rem;
  white-space: nowrap;
}

.deleteButton:hover {
  background: var(--foreground);
}

.noteScreen {
  position: absolute;
  height: 100%;
  width: 100%;
  cursor: pointer;
}

.noteScreen.hidden {
  visibility: hidden;
}

.noteScreen.overlay {
  z-index: 1000;
}
