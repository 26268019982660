.colourSelector {
  display: flex;
  flex-wrap: wrap;
  width: 10rem;
  margin: 0.25rem;
}

.colour {
  border: none;
  display: inline-block;
  border-radius: 2.25rem;
  margin: 2px;
  height: 2.25rem;
  width: 2.25rem;
  transition: opacity 150ms ease-in-out;
}

.colour:hover {
  opacity: 0.5;
}
