.createNote {
  position: relative;
  max-width: 25rem;
  margin: auto;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  cursor: pointer;
}

.modalContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
  margin: 5rem;
}

.modalContent svg {
  width: 3rem;
  height: 3rem;
  color: hsl(var(--red));
}

.modalContent p {
  text-align: center;
}

.modalContent i {
  margin-bottom: 0.5rem;
}

@media (max-width: 768px) {
  .modalContent {
    margin: 3rem;
  }
}
