.tagInput {
  font-size: 0.875rem;
  outline: none;
  color: var(--foreground);
  background: var(--accent-1);
  border: 1px solid var(--accent-3);
  width: 100%;
  padding: 0 1rem;
  border-radius: 2.25rem;
  height: 2.25rem;
}

.addNewTag {
  padding: 1rem;
  display: flex;
  gap: 0.5rem;
}

.addTagButton {
  height: 2.25rem;
  width: 2.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--accent-2);
  color: var(--foreground);
  border-radius: 2.25rem;
  flex-shrink: 0;
  border: 1px solid var(--accent-4);
}

.addTagButton > svg {
  width: 1rem;
  height: 1rem;
}

.addTagButton:hover {
  background: var(--accent-3);
}

.existingTags {
  border-bottom: 1px solid var(--accent-3);
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.existingTag {
  font-size: 0.875rem;
  text-overflow: ellipsis;
  cursor: pointer;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  color: var(--foreground-secondary);
  transition: all 250ms ease;
}

.existingTag:hover {
  color: var(--foreground);
}

.existingTag.active {
  color: var(--foreground);
}

.tagName {
  display: flex;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
}

.clearTag {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 0.5rem;
  color: hsl(var(--red));
  transition: all 250ms ease;
}

.clearTag:hover {
  color: var(--foreground);
}

.clearTag > svg {
  width: 1rem;
  height: 1rem;
}

.noTagsText {
  color: var(--foreground-secondary);
  text-align: center;
}
