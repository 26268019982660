.utilityContainer {
  position: relative;
  display: flex;
  opacity: 0;
  transition: all 250ms ease;
  padding: 0 0.25rem 0.25rem;
  align-self: flex-end;
}

.utilityContainer.show {
  opacity: 1;
}
