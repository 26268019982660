.iconButton {
  background: transparent;
  color: var(--foreground);
  opacity: 0.7;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  height: 2.25rem;
  width: 2.25rem;
  border: none;
}

.iconButton:hover {
  opacity: 1;
}

.iconButton > svg {
  height: 1.25rem;
  width: 1.25rem;
}
