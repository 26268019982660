.dropdownMenu {
  max-width: 22rem;
  position: absolute;
  top: 2.75rem;
  right: -1px;
  border-radius: 1rem;
  visibility: hidden;
  opacity: 0;
  transition: all 250ms ease;
  z-index: 1000;
  background: var(--accent-1);
  transform: translateY(-0.5rem);
  border: 1px solid var(--accent-3);
  overflow: hidden;
}

.dropdownMenu.show {
  visibility: visible;
  opacity: 1;
  transform: translateY(0);
}
