.guide {
  margin: 4rem;
}

.guide h2 {
  font-size: 1.5rem;
  padding-bottom: 0.5rem;
  margin-bottom: 1rem;
}

.guide p {
  margin-top: 1rem;
  text-align: left;
  color: var(--foreground-secondary);
}

@media (max-width: 768px) {
  .guide {
    margin: 4rem 2rem 2rem;
  }
}
