.noteText {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  padding: 1rem;
}

.title,
.content {
  width: 100%;
  border: none;
  padding: 0.25rem;
  outline: none;
  font-family: inherit;
  resize: none;
  color: var(--foreground);
  margin: 0;
  overflow: hidden;
}

.title {
  height: calc(37rem / 16);
  font-weight: 400;
  font-size: 1.25rem;
  background: var(--transparent);
}

.content {
  height: 1rem;
  font-size: 0.875rem;
  background: var(--transparent);
  line-height: 1.5;
}
