.noteBoardWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 2rem 0;
}

.noteBoard {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  max-width: 80rem;
  width: 100%;
  min-width: 0;
}

@media (max-width: 768px) {
  .note-board-wrapper {
    margin: 1.5rem 0;
  }
}
