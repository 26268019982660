.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  height: 100dvh;
  width: 100%;
  align-items: center;
  overflow: auto;
  z-index: 2;
  background: hsl(0 0% 0% / 20%);
  backdrop-filter: blur(20px);
  transition: all 250ms ease;
  padding: 0 1rem;
}

.modal {
  max-width: 75rem;
  width: 100%;
  background: var(--accent-1);
  border-radius: 1rem;
  transition: all 250ms ease;
  transform-origin: top center;
  transform: scale(0.8);
  flex-shrink: 0;
  margin: 5rem auto;
  position: relative;
  display: flex;
  flex-direction: column;
  border: 1px solid var(--accent-3);
}

.closeWrapper {
  position: sticky;
  top: 0;
  right: 0;
  align-self: flex-end;
}

.close {
  height: 2.25rem;
  width: 2.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--accent-2);
  cursor: pointer;
  border-radius: 2.25rem;
  transition: background 250ms ease;
  position: absolute;
  top: 1rem;
  right: 1rem;
  border: 1px solid var(--accent-4);
}

.closeIcon {
  height: 1.25rem;
  width: 1.25rem;
  color: var(--foreground);
  transition: transform 250ms ease;
}

.close:hover {
  background: var(--accent-3);
}

.close:hover .closeIcon {
  transform: rotateZ(90deg);
}

.modalTitle {
  font-size: 1.875rem;
}
